import Seafood from '../Images/metadata/Seafood.png';
import SeafoodFish from '../Images/metadata/Seafood-Fish.png';
import SeafoodShrimp from '../Images/metadata/Seafood-Shrimp.png';
import SeafoodProcessedSeafood from '../Images/metadata/Seafood-Processed-Seafood.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const seafood: IMetadataMap = {
  Seafood: {
    en: {
      title: 'Buy seafood, fish, shrimp for restaurants, hotels',
      description:
        'Kamereo provides wholesale and retail seafood, fish, shrimp at good prices in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Seafood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Cá, tôm, hải sản cho nhà hàng, khách sạn',
      description:
        'Kamereo cung cấp sỉ, lẻ các sản phẩm Cá, tôm, hải sản giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Seafood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seafood/Fish': {
    en: {
      title: 'Provide Salmon, fish balls for restaurants, hotels',
      description:
        'Fresh, delicious, eye-catching color Salmon, genuine fish balls,... Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: SeafoodFish,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Cá hồi, cá viên cho nhà hàng, khách sạn',
      description:
        'Cá hồi đông lạnh tươi ngon, màu sắc bắt mắt, cá viên chính hãng,... Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: SeafoodFish,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seafood/Shrimp': {
    en: {
      title: 'Provide Shrimp peeled for restaurants, hotels',
      description:
        'Buy Shrimp at good prices, quality goods come to Kamereo. Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: SeafoodShrimp,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Tôm thẻ lột vỏ cho nhà hàng, khách sạn',
      description:
        'Mua Tôm giá tốt, hàng chất lượng đến ngay Kamereo. Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: SeafoodShrimp,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seafood/Processed Seafood': {
    en: {
      title: 'Delicious, nutritious and convenient processed seafood',
      description:
        'Buy ready-to-eat seafood, safe, quality and good price at Kamereo. Buy online to receive many promotions, delivery to your place',
      image: {
        url: SeafoodProcessedSeafood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Hải sản chế biến sẵn thơm ngon, dinh dưỡng và tiện lợi',
      description:
        'Mua ngay Hải sản chế biến sẵn, an toàn, chất lượng và giá tốt tại Kamereo. Mua online nhận nhiều ưu đãi, giao hàng tận nơi.',
      image: {
        url: SeafoodProcessedSeafood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default seafood;
