import Beverage from '../Images/metadata/Beverage.png';
import BeverageWater from '../Images/metadata/Beverage-Water.png';
import BeverageTea from '../Images/metadata/Beverage-Tea.png';
import BeverageSoftDrink from '../Images/metadata/Beverage-Soft-Drink.png';
import BeverageFreshJuice from '../Images/metadata/Beverage-Fresh-Juice.png';
import BeverageCoffee from '../Images/metadata/Beverage-Coffee.png';
import BeverageOther from '../Images/metadata/Beverage-Other.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const beverage: IMetadataMap = {
  Beverage: {
    en: {
      title: 'Buy beverages, beer, soft drinks, mineral water, soft drinks at good prices',
      description:
        'Kamereo provides wholesale and retail beverages, beer, soft drinks, mineral water, soft drinks at good prices in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Beverage,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Đồ uống, bia, nước ngọt, nước suối, nước giải khát giá tốt',
      description:
        'Kamereo cung cấp sỉ, lẻ Đồ uống, bia, nước ngọt, nước suối, nước giải khát giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Beverage,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Water': {
    en: {
      title: 'Mineral water, purified water, mineral water at Kamereo',
      description:
        'Wholesale and retail Mineral water, purified water, mineral water,... at good prices, stable price, support invoice, delivery to your place',
      image: {
        url: BeverageWater,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nước suối, nước tinh khiết, nước khoáng tại Kamereo',
      description:
        'Nhập sỉ lẻ Nước suối, nước tinh khiết, nước khoáng,... giá cả ổn định, có hỗ trợ xuất hóa đơn, giao hàng tận nơi',
      image: {
        url: BeverageWater,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Fresh Juice': {
    en: {
      title: 'Provide Orange juice, apple juice,... for restaurants, hotels',
      description:
        'Wholesale and retail Orange juice, apple juice,... for restaurants, hotels, cafes,... at good prices, stable price, support invoice, delivery to your place',
      image: {
        url: BeverageFreshJuice,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Nước ép cam, táo,... cho nhà hàng, khách sạn',
      description:
        'Nhập sỉ lẻ Nước ép cam, táo,...  cho nhà hàng, khách sạn, cà phê,... giá cả ổn định, có hỗ trợ xuất hóa đơn, giao hàng đúng giờ, tận nơi',
      image: {
        url: BeverageFreshJuice,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Tea': {
    en: {
      title: 'Buy Green tea, dried tea, tea bags at good prices at Kamereo',
      description:
        'Buy Green tea, dried tea, tea bags at good prices, guaranteed origin, many attractive promotions, delivery to your place at Kamereo',
      image: {
        url: BeverageTea,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua ngay Trà xanh, trà khô, trà túi lọc giá tốt tại Kamereo',
      description:
        'Mua ngay các sản phẩm Trà xanh, trà khô, trà túi lọc giá tốt, nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi tại Kamereo',
      image: {
        url: BeverageTea,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Coffee': {
    en: {
      title: 'Buy whole coffee beans, strong coffee filters,... at Kamereo',
      description:
        'Buy wholesale and retail whole coffee beans, strong coffee filters,... for restaurants, hotels, cafes,... at good prices, stable price, support invoice, delivery to your place',
      image: {
        url: BeverageCoffee,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nhập cà phê nguyên hạt, cà phê phin đậm,... tại Kamereo',
      description:
        'Mua sỉ lẻ cà phê nguyên hạt, cà phê phin đậm,... cho nhà hàng, khách sạn, cà phê... giá cả ổn định, có hỗ trợ xuất hóa đơn, giao hàng đúng giờ, tận nơi',
      image: {
        url: BeverageCoffee,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Other': {
    en: {
      title: 'Provide Mixing ingredients for restaurants, hotels',
      description:
        'Wholesale and retail Mixing ingredients for restaurants, hotels, cafes,... at good prices, stable price, support invoice, delivery to your place',
      image: {
        url: BeverageOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Nguyên liệu pha chế cho nhà hàng, khách sạn',
      description:
        'Mua sỉ lẻ Nguyên liệu pha chế cho nhà hàng, khách sạn, cà phê... giá cả ổn định, có hỗ trợ xuất hóa đơn, giao hàng đúng giờ, tận nơi',
      image: {
        url: BeverageOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Beverage/Soft Drink': {
    en: {
      title: 'Buy genuine and safe soft drinks at Kamereo',
      description:
        'Hundreds of soft drinks, energy drinks, tea, soft drinks with many brands such as Coca, Pepsi, 7up,... many promotions at Kamereo',
      image: {
        url: BeverageSoftDrink,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nước ngọt chính hãng, an toàn tại Kamereo',
      description:
        'Hàng trăm sản phẩm Nước ngọt, nước tăng lực, nước trà, nước giải khát với nhiều thương hiệu như Coca, Pepsi, 7up,...nhiều ưu đãi tại Kamereo.',
      image: {
        url: BeverageSoftDrink,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default beverage;
