import { IMetadataMap } from './types';
import veggies from './veggies';
import meat from './meat';
import beverage from './beverage';
import dryfood from './dry-food';
import seasoning from './seasoning';
import seafood from './seafood';
import dairy from './dairy';
import alcohol from './alcohol';
import nonfood from './non-food';
import tags from './tags';
import market from './market';
import search from './search';

const METADATA: IMetadataMap = {
  ...market,
  ...veggies,
  ...meat,
  ...beverage,
  ...dryfood,
  ...seasoning,
  ...seafood,
  ...dairy,
  ...alcohol,
  ...nonfood,
  ...tags,
  ...search,
};

export default METADATA;
