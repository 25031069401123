import { createMatchSelector, push } from 'connected-react-router';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getProduct, getProductBySupplierInternalCode, getProducts } from 'utils/apollo';
import generateProductSlug from 'utils/generateProductSlug';
import isValidUUID from 'utils/isValidUUID';
import parseSlug from 'utils/parseSlug';
import { product as productActions, relateProducts as relateProductsActions } from './actions';
import { LANGUAGES } from 'utils/constants';
import selectProductDetailsPage from './selectors';
import { Product } from 'types/schema';
import { setAlcoholState } from 'containers/MainLayout/actions';
import ActionTypes from './constants';
import { selectLang, selectViewportWidth } from 'containers/MainLayout/selectors';

function generateProductUrl(product: Product, lang: string) {
  return `${generateProductSlug(product.nameLocal?.[lang], product.supplierInternalCode)}${lang === 'vi' ? '' : `?lang=${lang}`}`;
}

function* getProductDetails() {
  try {
    const route = yield select(createMatchSelector('/products/:slug'));
    const slug = route.params.slug;
    const localLang = yield select(selectLang());
    const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate : LANGUAGES.Vi;
    let uuid: string = isValidUUID(slug) && slug;
    // const isNotPreloadState = loggedIn || !state.product?.id || state.product?.supplierInternalCode !== parseSlug(slug);
    yield put(productActions.request());
    if (!uuid) {
      const supplierInternalCode = parseSlug(route.params.slug);

      const productBySkuCodeResponse = yield call(getProductBySupplierInternalCode, {
        supplierInternalCode: supplierInternalCode,
      });
      uuid = productBySkuCodeResponse.id;
    }
    // To fetch full information of product
    const response = yield call(getProduct, { id: uuid });
    if (!response.errors) {
      yield handleProductDetailsSuccess(response, localLang, alternateLang);
    } else {
      yield put(push('/not-found'));
    }
    const isAlcoholCategory = response?.category?.parent?.name === 'Alcohol';
    if (isAlcoholCategory) {
      yield put(setAlcoholState(true));
    }
  } catch (error) {
    yield put(productActions.failure(error as Error));
  }
}

function* handleProductDetailsSuccess(response: Product, localLang: string, alternateLang: string) {
  yield put(
    productActions.success({
      ...response,
      loading: false,
      localLang: localLang,
      alternateLang: alternateLang,
      validProductUrl: generateProductUrl(response, localLang),
      alternateProductUrl: generateProductUrl(response, alternateLang),
    }),
  );
  window.localStorage.setItem('lang', localLang as string);
  import('utils/triggerGA4Event')
    .then((module) => {
      const triggerGA4EcommerceEvent = module.default;
      const { EventName } = module;
      triggerGA4EcommerceEvent(EventName.VIEW_ITEM, response);
    })
    .catch((error) => {
      // tslint:disable-next-line: no-console
      console.error('Failed to load GA4 module:', error);
    });
  // Fetch relate products
  yield fork(getRelateProducts, response);
}

function* getRelateProducts(product, size = 7) {
  const viewportWidth = yield select(selectViewportWidth());
  if (viewportWidth >= 576 || size > 7) {
    yield put(relateProductsActions.request());
    const response = yield call(getProducts, {
      sort: [],
      filter: {
        categoryName: `${product.category.parent.name}/${product.category.name}`,
      },
      pagination: {
        page: 0,
        size: size
      },
    });
    if (!response.errors) {
      const data = response.data.filter((item) => item.id !== product.id).slice(0, size);
      yield put(relateProductsActions.success(data));
    } else {
      yield put(relateProductsActions.failure(response.errors));
    }
  } else {
    yield put(relateProductsActions.success([]));
  }
}

function* loadMoreRelateProducts() {
  const state = yield select(selectProductDetailsPage());
  const product = state.product;
  yield call(getRelateProducts, product, 25);
}

function* initData() {
  yield call(getProductDetails);
}

// Individual exports for testing
export default function* productDetailsPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initData);
  yield takeLatest(ActionTypes.FETCH_MORE_RELATED_PRODUCTS_REQUEST, loadMoreRelateProducts)
}
