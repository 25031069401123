import KamereoLogo from '../../../images/KamereoLogo.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const tags: IMetadataMap = {
  BEST_DEALS: {
    en: {
      title: 'Buy Ingredients and food at shockingly discounted prices at Kamereo',
      description:
        'Kamereo provides wholesale and retail food, fresh ingredients for restaurants, hotels, coffee shops,... in HCM City, Binh Duong, Ha Noi. Guaranteed origin, many attractive promotions, support invoice, delivery to your place. Good prices every week.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nhập Nguyên liệu, thực phẩm với giá giảm sốc tại Kamereo',
      description:
        'Kamereo là nhà cung cấp thực phẩm, nguyên liệu tươi ngon cho nhà hàng, khách sạn, cà phê,... tại TP.HCM, Bình Dương, Hà Nội. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, có xuất hóa đơn, giao hàng tận nơi. Giá tốt mỗi tuần.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  BEST_PRICES: {
    en: {
      title: 'Buy ingredients, convenient foods at good prices at Kamereo',
      description:
        'Kamereo provides wholesale and retail food, fresh ingredients for restaurants, hotels, coffee shops,... in HCM City, Binh Duong, Ha Noi. Guaranteed origin, many attractive promotions, support invoice, delivery to your place. Good prices every week.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nhập Nguyên liệu, thực phẩm tiện lợi, giá tốt mỗi tuần tại Kamereo',
      description:
        'Kamereo là nhà cung cấp thực phẩm, nguyên liệu tươi ngon cho nhà hàng, khách sạn, cà phê,... tại TP.HCM, Bình Dương, Hà Nội. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, có xuất hóa đơn, giao hàng tận nơi.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  FLASH_SALES: {
    en: {
      title: 'Kamereo Flash Sale every Tuesday and Thursday, helping restaurants do business easily',
      description:
        'Kamereo is a provider of food, fresh ingredients for restaurants, hotels, coffee shops,... in HCM City, Binh Duong, Ha Noi. Guaranteed origin, many attractive promotions, support invoice, delivery to your place. Flash sale every Tuesday and Thursday.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Kamereo Flash Sale thứ 3 và thứ 5, giúp nhà hàng kinh doanh dễ dàng',
      description:
        'Kamereo là nhà cung cấp thực phẩm, nguyên liệu tươi ngon cho nhà hàng, khách sạn, cà phê,... tại TP.HCM, Bình Dương, Hà Nội. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, có xuất hóa đơn, giao hàng tận nơi. Flash sale mỗi thứ 3 và thứ 5.',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  DEFAULT: {
    en: {
      generateTitle: (keyword: string) => `${keyword} | Buy Good Deals, Quality at Kamereo`,
      generateDescription: (keyword: string) => `Discover ${keyword} good deals, quality selected with clear origin. Suitable for restaurants, eateries, supermarkets, and many other businesses. Order now to receive promotions and fast delivery.`,
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
      title: 'Tag',
      description: 'Tag product page'
    },
    vi: {
      generateTitle: (keyword: string) => `${keyword} | Nhập hàng Giá Tốt, Chất Lượng tại Kamereo`,
      generateDescription: (keyword: string) => `Khám phá ${keyword} ưu đãi tốt, chất lượng chọn lọc tốt với nguồn gốc rõ ràng. Phù hợp cho nhà hàng, quán ăn, siêu thị, và nhiều doanh nghiệp khác. Đặt hàng ngay để nhận ưu đãi và giao hàng nhanh chóng.`,
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
      title: 'Tag',
      description: 'Trang sản phẩm theo tag'
    },
  }
};

export default tags;
