import KamereoLogo from '../../../images/KamereoLogo.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const market: IMetadataMap = {
  market: {
    en: {
      title: 'Market | KAMEREO',
      description: 'Market | KAMEREO',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Chợ | KAMEREO',
      description: 'Chợ | KAMEREO',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default market;
