import NonFood from '../Images/metadata/Non-food.png';
import NonFoodKitchenware from '../Images/metadata/Non-food-Kitchenware.png';
import NonFoodDetergent from '../Images/metadata/Non-food-Detergent.png';
import NonFoodOther from '../Images/metadata/Non-food-Other.png';
import NonFoodTissueTissueDispenser from '../Images/metadata/Non-food-Tissue-Tissue-Dispenser.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const nonfood: IMetadataMap = {
  'Non-food': {
    en: {
      title: 'Supply Essential items for restaurants, hotels, cafes',
      description:
        'Kamereo provides wholesale and retail Essential items for restaurants, hotels, cafes in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: NonFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Đồ dùng thiết yếu cho nhà hàng, khách sạn, cà phê',
      description:
        'Kamereo cung cấp sỉ, lẻ Đồ dùng thiết yếu cho nhà hàng, khách sạn, cà phê tại thành phố Hồ Chí Minh. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: NonFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Non-food/Kitchenware': {
    en: {
      title: 'Supply Kitchenware for restaurants, hotels',
      description:
        'Kamereo provides wholesale and retail Kitchenware for restaurants, hotels, cafes... Delivery on time, support invoice. Buy Online at Kamereo',
      image: {
        url: NonFoodKitchenware,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Đồ dùng bếp cho nhà hàng, khách sạn',
      description:
        'Cung cấp các Đồ dùng bếp, quầy bar... cho nhà hàng, khách sạn, cà phê... Giao hàng đúng giờ, có xuất hóa đơn. Nhập hàng online tiện lợi tại Kamereo',
      image: {
        url: NonFoodKitchenware,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Non-food/Detergent': {
    en: {
      title: 'Supply Toilet cleaner tablets, bathroom toilet cleaner at Kamereo',
      description:
        'Kamereo provides wholesale and retail Toilet cleaner, toilet water for bathroom at good prices in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: NonFoodDetergent,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Viên tẩy bồn cầu, nước tẩy bồn cầu nhà tắm tại Kamereo',
      description:
        'Cung cấp các Đồ dùng vệ sinh toilet cho nhà hàng, khách sạn, cà phê... Giao hàng đúng giờ, có xuất hóa đơn. Nhập hàng online tiện lợi tại Kamereo',
      image: {
        url: NonFoodDetergent,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Non-food/Tissue & Tissue Dispenser': {
    en: {
      title: 'Supply Paper towels, supplies for restaurants, hotels at good prices',
      description:
        'Kamereo provides wholesale and retail Tissue, Tissue Dispenser for restaurants, hotels, cafes... Delivery on time, support invoice. Buy Online at Kamereo',
      image: {
        url: NonFoodTissueTissueDispenser,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nhập Khăn giấy, vật dụng cho nhà hàng, khách sạn giá tốt',
      description:
        'Cung cấp Khăn giấy, vật dụng cho nhà hàng, khách sạn, cà phê... Giao hàng đúng giờ, có xuất hóa đơn. Nhập hàng online tiện lợi tại Kamereo',
      image: {
        url: NonFoodTissueTissueDispenser,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Non-food/Other': {
    en: {
      title: 'Supply Essential items for F&B businesses',
      description:
        'Kamereo provides wholesale and retail Essential items for restaurants, hotels, cafes in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: NonFoodOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Vật dụng thiết yếu cho doanh nghiệp F&B',
      description:
        'Cung cấp Vật dụng thiết yếu cho nhà hàng, khách sạn, cà phê... Giao hàng đúng giờ, có xuất hóa đơn. Nhập hàng online tiện lợi tại Kamereo',
      image: {
        url: NonFoodOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default nonfood;
