import Alcohol from '../Images/metadata/Alcohol.png';
import AlcoholJapaneseSakeShochu from '../Images/metadata/Alcohol-Japanese-Sake-Shochu.png';
import AlcoholBottleCanBeer from '../Images/metadata/Alcohol-Bottle-Can-Beer.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const alcohol: IMetadataMap = {
  Alcohol: {
    en: {
      title: 'Buy wholesale and retail beer and wine of all kinds at good prices at Kamereo',
      description:
        'Kamereo provides wholesale and retail Beer, wine from brands in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Alcohol,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua sỉ lẻ Bia, rượu các loại giá tốt tại Kamereo',
      description:
        'Kamereo cung cấp sỉ, lẻ Bia, rượu từ các thương hiệu tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Alcohol,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Alcohol/Bottle & Can Beer': {
    en: {
      title: 'Buy beer of all kinds at good prices at Kamereo',
      description:
        'Providing Beer Tiger, Heineken, Corona, Saporo,... for restaurants, hotels, bar pub,... Delivery on time, support invoice',
      image: {
        url: AlcoholBottleCanBeer,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Nhập Bia các loại giá tốt tại Kamereo',
      description:
        'Cung cấp các loại Bia Tiger, Heineken, Corona, Saporo,... cho quán ăn, nhà hàng, khách sạn, bar pub,... Giao hàng đúng giờ, có xuất hóa đơn',
      image: {
        url: AlcoholBottleCanBeer,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Alcohol/Japanese Sake & Shochu': {
    en: {
      title: 'Provide Plum wine, sochu of all kinds for restaurants, hotels',
      description:
        'Providing Plum wine, sochu,... for restaurants, hotels... Delivery on time, support invoice. Buy online at Kamereo',
      image: {
        url: AlcoholJapaneseSakeShochu,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Rượu mơ, sochu các loại cho nhà hàng, khách sạn',
      description:
        'Cung cấp các loại Rượu mơ, sochu... cho nhà hàng, khách sạn... Giao hàng đúng giờ, có xuất hóa đơn. Nhập hàng online tiện lợi tại Kamereo',
      image: {
        url: AlcoholJapaneseSakeShochu,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default alcohol;
