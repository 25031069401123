import Seasoning from '../Images/metadata/Seasoning.png';
import SeasoningVinegar from '../Images/metadata/Seasoning-Vinegar.png';
import SeasoningCookingPowder from '../Images/metadata/Seasoning-Cooking-Powder.png';
import SeasoningHoney from '../Images/metadata/Seasoning-Honey.png';
import SeasoningOil from '../Images/metadata/Seasoning-Oil.png';
import SeasoningSauce from '../Images/metadata/Seasoning-Sauce.png';
import SeasoningOther from '../Images/metadata/Seasoning-Other.png';
import SeasoningSyrup from '../Images/metadata/Seasoning-Syrup.png';
import SeasoningSeasoning from '../Images/metadata/Seasoning-Seasoning.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const seasoning: IMetadataMap = {
  Seasoning: {
    en: {
      title: 'Supply Cooking oil, seasoning, spices for restaurants, hotels',
      description:
        'Cooking oil, seasoning, spices, food coloring, cashew nuts, turmeric powder, garlic powder, five-spice powder, ginger powder, cloves, cinnamon, star anise, cardamom, delivery to your place, support invoice',
      image: {
        url: Seasoning,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Dầu ăn, nước nắm, gia vị cho nhà hàng, khách sạn',
      description:
        'Dầu ăn, nước nắm, gia vị, nước màu, hạt điều, bột nghệ, bột tỏi, bột ngũ vị hương, bột sả gừng, hồi, quế, thảo quả các loại, giao hàng tận hơi, có xuất hóa đơn',
      image: {
        url: Seasoning,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Seasoning': {
    en: {
      title: 'Spices for restaurants, hotels',
      description:
        'Providing Salt, sugar, chili, pepper, sesame oil, cinnamon, star anise... guaranteed quality, safe for restaurants, hotels, F&B businesses',
      image: {
        url: SeasoningSeasoning,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Gia vị nấu ăn cho nhà hàng, khách sạn',
      description:
        'Cung cấp Muối, đường, ớt, tiêu, dầu mè, quế, hoa hồi... đảm bảo chất lượng, an toàn cho nhà hàng, khách sạn, doanh nghiệp F&B',
      image: {
        url: SeasoningSeasoning,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Sauce': {
    en: {
      title: 'Wholesale and retail Fish sauce, soy sauce, oyster sauce, chili sauce,...',
      description:
        'Providing Fish sauce, soy sauce, oyster sauce, chili sauce... guaranteed quality, safe for restaurants, hotels, F&B businesses',
      image: {
        url: SeasoningSauce,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Sỉ lẻ Nước mắm, nước tương, dầu hào, tương ớt,...',
      description:
        'Cung cấp Nước mắm, nước tương, dầu hào, tương ớt... đảm bảo chất lượng, an toàn cho nhà hàng, khách sạn, doanh nghiệp F&B',
      image: {
        url: SeasoningSauce,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Oil': {
    en: {
      title: 'Buy clean, quality, good price Cooking oil at Kamereo',
      description: 'Buy Cooking oil from brands, support invoice, delivery on time, to your place at Kamereo.',
      image: {
        url: SeasoningOil,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Dầu ăn sạch, chất lượng, giá tốt tại Kamereo',
      description:
        'Mua ngay các sản phẩm Dầu ăn từ các thương hiệu, có xuất hóa đơn, giao hàng đúng giờ, tận nơi tại Kamereo.',
      image: {
        url: SeasoningOil,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Vinegar': {
    en: {
      title: 'Wholesale and retail Vinegar, apple vinegar, grape vinegar',
      description:
        'Nutritious Vinegar, apple vinegar, grape vinegar are produced according to modern technology, hygienic, with reasonable prices are available at Kamereo.',
      image: {
        url: SeasoningVinegar,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Sỉ lẻ Giấm gạo, giấm táo, giấm nho ',
      description:
        'Các loại Giấm ăn, giấm táo, giấm nho bổ dưỡng được sản xuất theo công nghệ hiện đại, hợp vệ sinh, cùng giá thành hợp lý đang có bán tại Kamereo.',
      image: {
        url: SeasoningVinegar,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Cooking Powder': {
    en: {
      title: 'Wholesale and retail Seasoning powder, cooking powder at good prices | KAMEREO',
      description:
        'Buy Seasoning powder, cooking powder, soup powder,... for restaurants, hotels,.... Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: SeasoningCookingPowder,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Sỉ lẻ Hạt nêm, bột ngọt nấu ăn các loại giá tốt | KAMEREO',
      description:
        'Mua Hạt nêm, bột ngọt, bột canh,... các loại cho nhà hàng, khách sạn,.... Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: SeasoningCookingPowder,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Honey': {
    en: {
      title: 'Real and genuine honey at Kamereo',
      description:
        'Providing real, genuine honey for coffee, restaurants, hotels,... Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: SeasoningHoney,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mật ong thật, nguyên chất chính hãng tại Kamereo',
      description:
        'Cung cấp Mật ong nguyên chất cho cà phê, nhà hàng, khách sạn.... Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: SeasoningHoney,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Syrup': {
    en: {
      title: 'Syrup of all kinds, full of flavors',
      description:
        'Buy all kinds of Syrup for coffee, restaurants, hotels,... Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: SeasoningSyrup,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Siro Torani các loại, đủ hương vị',
      description:
        'Mua Siro các loại cho cà phê, nhà hàng, khách sạn,.... Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: SeasoningSyrup,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  'Seasoning/Other': {
    en: {
      title: '',
      description: '',
      image: {
        url: SeasoningOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: '',
      description: '',
      image: {
        url: SeasoningOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default seasoning;
