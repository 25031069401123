function getHeightFromAspectRatio(
  width: number,
  aspectRatio: string,
  format: 'percentage' | 'number' = 'number',
): number {
  if (!aspectRatio.includes(':')) {
    throw new Error('Invalid aspect ratio, expected a string in the format "width:height"');
  }
  const [aspectWidth, aspectHeight] = aspectRatio.split(':').map(Number);

  return format === 'percentage'
    ? Math.round((aspectHeight / aspectWidth) * 100)
    : Math.round((width * aspectHeight) / aspectWidth);
}

export default getHeightFromAspectRatio;
