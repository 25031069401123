import { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash';

const useScrollTracking = (loadMore: () => void, position = 100) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      if (!hasScrolled && /* window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000 */ window.scrollY > position) {
        setHasScrolled(true);
        loadMore();
      }
    }, 100), // Adjust the debounce delay as needed
    [hasScrolled, loadMore]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return hasScrolled;
};

export default useScrollTracking;