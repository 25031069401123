/*
 * ProductDetailsPage Messages
 *
 * This contains all the text for the ProductDetailsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ProductDetailsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chi tiết sản phẩm',
  },
  similarProducts: {
    id: `${scope}.similarProducts`,
    defaultMessage: 'Sản phẩm tương tự',
  },
  productDescription: {
    id: `${scope}.productDescription`,
    defaultMessage: 'Mô tả sản phẩm',
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'Xem thêm',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Thu gọn',
  },
});
