import KamereoLogo from '../../../images/KamereoLogo.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const search: IMetadataMap = {
  search: {
    en: {
      title: 'Search',
      description: 'Search for products',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
      generateTitle: (keyword: string) => {
        const date = new Date();
        return `Buy ${keyword} at good prices in ${
          date.getMonth() + 1
        }/${date.getFullYear()}, guaranteed quality - Kamereo`;
      },
      generateDescription: (keyword: string) =>
        `Buy ${keyword} quality, many attractive promotions, delivery on time, have an invoice at Kamereo. Kamereo specializes in providing ${keyword} and many other products for restaurants, hotels, cafes....`,
    },
    vi: {
      title: 'Tìm kiếm',
      description: 'Trang tìm kiếm sản phẩm',
      image: {
        url: KamereoLogo,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
      generateTitle: (keyword: string) => {
        const date = new Date();
        return `Mua ${keyword} giá tốt tháng ${
          date.getMonth() + 1
        }/${date.getFullYear()}, cam kết chất lượng - Kamereo`;
      },
      generateDescription: (keyword: string) =>
        `Mua ${keyword} chất lượng, nhiều khuyến mãi hấp dẫn, giao hàng đúng giờ, có xuất hóa đơn tại Kamereo. Kamereo chuyên cung cấp ${keyword} và nhiều sản phẩm khác cho nhà hàng, khách sạn, cà phê....`,
    },
  },
};

export default search;
